<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage programs</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Programs
                </div>
              </div>
              <div class="breadcrumb-right" v-if="checkIsAccessible('program', 'create')">
                <div class="card-toolbar">
                  <v-btn
                    @click="createProgram()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    program
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Program name"
                    type="text"
                    dense
                    outlined
                    v-on:keyup.enter="getPrograms"
                    v-model="search.title"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-on:keyup.enter="getPrograms"
                    v-model="search.active"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click.prevent="getPrograms"
                    class="btn btn-primary"
                    style="color: #fff"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column" colspan="2">Title</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="programs.length > 0">
                    <tr v-for="(program, index) of programs" :key="index">
                      <td class="wrap-column px-3">
                        <a class="link" @click="editProgram(program.id)">
                          {{ program.title }}
                        </a>
                        &nbsp;&nbsp;<i
                          class="fas fa-circle"
                          :class="program.is_active ? 'dot-active' : 'dot-inactive'"
                        ></i>
                        <br />
                      </td>
                      <td class="wrap-column px-3 py-3">
                        <span
                          :class="
                            program.display_in_enrollment
                              ? 'badge badge-primary'
                              : 'badge badge-warning'
                          "
                          >{{
                            program.display_in_enrollment
                              ? "Admission Open"
                              : "Admission Closed"
                          }}</span
                        >
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown"
                            no-caret
                            right
                            no-flip
                            text="Actions"
                            v-if="checkIsAccessible('program', 'edit') || checkIsAccessible('program', 'delete')"
                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">

                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('academic-year', 'edit') && program.program_fee_id && !program.is_auto_increment_fee && program.is_active">
                                <a class="navi-link" @click="enableIncrementFee(program.id, program.program_fee_id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-money-bill"></i>
                                  </span>
                                  <span class="navi-text">Enable increment fee</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-year', 'edit') && program.program_fee_id && program.is_auto_increment_fee && program.is_active">
                                <a class="navi-link" @click="disableIncrementFee(program.id, program.program_fee_id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-money-bill-alt"></i>
                                  </span>
                                  <span class="navi-text">Disable increment fee</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                                v-if="checkIsAccessible('program', 'edit')"
                              >
                                <a
                                  href="#"
                                  class="navi-link"
                                  @click="editProgram(program.id)"
                                >
                                  <span class="navi-icon">
                                    <i class="flaticon-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                                v-if="checkIsAccessible('program', 'delete')"
                              >
                                <a
                                  href="#"
                                  class="navi-link"
                                  @click="__delete(program.id)"
                                >
                                  <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                  </span>
                                  <span class="navi-text"> Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                          <span v-else>N/A</span>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <template v-if="programs.length == 0">
                    <td class="px-3 text-center" colspan="2">Data not available</td>
                  </template>
                </tbody>
              </table>
              <b-pagination
                v-if="programs.length > 0"
                class="pull-right mt-7"
                @input="getPrograms"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>

        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import ProgramFeeService from "@/core/services/fee/programFee/ProgramFeeService";
import event from "@/core/plugins/event";

const programService = new ProgramService();
const programFee = new ProgramFeeService();

export default {
  name: "Program",
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      programs: [],
      program_id: null,
      loading: false,
      closeOnClick: true,
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
      },
    };
  },
  mounted() {
    this.getPrograms();

   },
  methods: {
    getPrograms() {
      this.loading = true;
      programService
        .paginate(this.search)
        .then((response) => {
          this.programs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;

        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          this.loading = false;
        });
    },

    editProgram(id) {
      this.$router.push({ name: "program-update", params: { id: id } });
    },

    createProgram() {
      event.on("refresh-program",  (data) => {
        this.getPrograms();
      });
      this.$router.push({
        name: "program-new",
      });
    },
    enableIncrementFee(programId, id) {
      this.$confirm({
        message: `Are you sure you want to enable auto increment fee this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            programFee.enableIncrementFee(programId, id).then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Information updated");
                this.getPrograms();
              }
            });
          }
        }
      });
    },

    disableIncrementFee(programId, id) {
      this.$confirm({
        message: `Are you sure you want to disable auto increment fee this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            programFee.disableIncrementFee(programId, id).then(response => {
              if(response.data.status == "OK") {
                this.$snotify.success("Information updated");
                this.getPrograms();
              }
            });
          }
        }
      });
    },
    __delete(id) {
      this.$confirm({
        auth: true,
        message: `Are you sure you want to delete this item? Type "password" as a Text To Confirm`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         * @param {String} password
         */
        callback: (confirm, password) => {
          if (confirm && password == "password") {
            programService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getPrograms();
            });
          }
        },
      });
    },
  },
};
</script>
